import React from 'react';
import './Loading.css';
function Loading() {
    
    return <div className = "loading-container">
        <div className = "loader">
    
        </div>
    </div>;
  }
  export default Loading;

